@import "../../Assets/scss/variable.scss";

// $color-Dark: #212121; // Background or text if white background
// $color-Dark-1: #191919;

// $color-Light: #e8e8e8; //Background or text if Black background
// $color-Light-1: #f5f5f5;

// $color-Pastel-Green: #5c7457; //for accent

// $color-White-Details-text: #d4d6d8; //if black background

// $color-Dark-Details-text: #5d5d5d; //if white background

// $color-Accent-1: #4455c7;

.products-container {
  //   padding: 2rem;

  .banner {
    position: relative;
    width: 100%;
    height: 370px;
    overflow: hidden;
    margin-bottom: 2rem;

    @media screen and (max-width: 700px) {
      height: 270px;
    }

    .banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #e8e8e8;
      font-size: 2.5rem;
      font-weight: 700;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      @media screen and (max-width: 400px) {
        font-size: 2rem;
      }
    }
  }

  .product-tabs-container {
    margin-top: 20px !important;
    margin-bottom: 40px !important;
    max-width: 1440px;
    margin: 0 auto;

    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: auto;
      max-width: 1240px;
      margin: 0 auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch; // For smooth scrolling on iOS
      scrollbar-width: none; // For Firefox
      -ms-overflow-style: none; // For Internet Explorer and Edge
      row-gap: 10px;
      column-gap: 10px;

      @media screen and (max-width: 400px) {
        margin-left: 10px;
        margin-right: 10px;
      }

      &::-webkit-scrollbar {
        display: none; // For WebKit-based browsers (Chrome, Safari)
      }

      .nav-tabs {
        flex-wrap: nowrap;
      }

      .nav-item {
        white-space: nowrap;
        display: inline-block;
      }

      .nav-item {
        flex-grow: unset;
        display: inline-block; // Ensure items are inline for scrolling
        white-space: nowrap;

        .nav-link {
          text-align: center;
          padding: 10px 15px;
          font-size: 1rem;
          font-weight: 600;
          color: $color-Dark;
          white-space: nowrap; // Prevent wrapping
          transition: background-color 0.2s, color 0.2s;
          //   border: 1px solid $color-Dark;
          border-radius: 12px;
          background-color: $color-Light-1;

          &:hover {
            background-color: $color-Light;
            border-radius: 12px;
          }

          &.active {
            background-color: $color-Pastel-Green;
            color: $color-Light;
            border-radius: 12px;
          }
        }
      }
    }

    .tab-content {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   margin-top: 20px;
      //   width: 100%;

      //   .tab-pane {
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     flex-direction: column;
      //     text-align: center;
      //     width: 100%;
      //   }

      .img-row {
        margin: 0;
        .tab-image {
          max-width: 100%;
          max-height: 500px;
          width: auto;
          height: auto;
          border-radius: 16px;
          margin-top: 20px;
        }
      }
    }
  }

  .cta {
    border: 2px solid linear-gradient(45deg, #5c7457, #4455c7);
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    padding: 1rem;
    border-radius: 50px;
    background-color: $color-Light-1;
    position: relative;
    margin: 0 auto;
    padding: 9px 18px;
    transition: all 0.2s ease;
    // border: none;
    background: none;
    cursor: pointer;
    display: flex; // Center text and svg inside the button
    align-items: center; // Center items vertically
    justify-content: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      border-radius: 50px;
      background: $color-Dark;
      width: 70px;
      height: 45px;
      transition: all 0.3s ease;

      @media screen and (max-width: 920px) {
        width: 100%;
      }
    }

    span {
      position: relative;
      font-family: "Ubuntu", sans-serif;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.05em;
      color: $color-Affirmative;
    }

    svg {
      position: relative;
      top: 0;
      margin-left: 10px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: $color-Affirmative;
      stroke-width: 2;
      transform: translateX(-5px);
      transition: all 0.3s ease;
    }

    &:hover {
      &:before {
        width: 100%;
        background: $color-Dark;
      }

      svg {
        transform: translateX(0);
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }
}
