@import "../../Assets//scss/variable.scss";

.section-padding {
  padding: 1rem;

  @media screen and (max-width: 1200px) {
    padding: 0;
  }
}
.footer {
  background-color: $color-Dark;
  color: $color-Light;
  text-align: center;
  padding: 1rem;
  //   margin: 1rem;
}
.sb-footer {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
}

.Logo {
  @media screen and (max-width: 1020px) {
    width: 120px;
  }
  @media screen and (max-width: 600px) {
    width: 100px;
  }
}
.sb-footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;

  //   .footer-top {
  //     display: flex;
  //     flex-direction: column;
  //     width: 100%;
  //   }
}

.width-50 {
  width: 50% !important;

  @media screen and (max-width: 1440px) {
    width: 40% !important;
  }
  @media screen and (max-width: 459px) {
    width: 100% !important;
  }
}

.sb-footer-links-div {
  //   width: 150px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  row-gap: 6px;
  flex-direction: column;
  color: $color-Light;

  a {
    text-decoration: underline;
    color: $color-Light-1;
  }

  .about-txt {
    margin-top: 8px;
    margin-bottom: 0;
  }

  h4 {
    margin-top: 0;
    font-size: 1.5rem;
    line-height: 3rem;
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    li {
      margin-top: 10px;
      &:not(:last-child) {
        @include breakpoint(md) {
          margin-bottom: 0;
          margin-right: $spacing-md;
        }
      }
      a {
        text-decoration: none;
        color: inherit;
        padding: 0.2rem 0.5rem;
        border-radius: $borderRadius;
        transition: 0.3s ease all;
        // margin-top: 20px;

        &:hover {
          background: rgba($color-Light, 0.1);
        }
      }
    }
  }
}

.social-media {
  display: flex;
  align-items: center;
  column-gap: 18px;

  .icons {
    cursor: pointer;
  }
}

.sb-footer-below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
}

hr {
  color: $color-Light-1;
  width: 100%;
}

.sb-footer-copyright {
  .copyright {
    font-size: 13px;
    line-height: 15px;
    color: $color-Light-1;
    font-weight: 600;
  }
}
