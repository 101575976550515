@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

// Fonts
$regulerFont: "Roboto", sans-serif;
$primaryFont: "VeneerClean-Soft" !important;
$secondaryRegularFont: "SF-PRO-Regular";
$secondarySemiboldFont: "SF-PRO-Semibold";
$arialFont: "Arial";

// Colors

$color-Dark: #212121; // Background or text if white background
$color-Dark-1: #191919;

$color-Light: #e8e8e8; //Background or text if Black background
$color-Light-1: #f5f5f5;

$color-Pastel-Green: #5c7457; //for accent

$color-White-Details-text: #d4d6d8; //if black background

$color-Dark-Details-text: #5d5d5d; //if white background

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;

// header height
$headerHeight: 100px;

@mixin breakpoint($point) {
  @if $point == md {
    // 768px
    @media (min-width: 48em) {
      @content;
    }
  }
}

$color-Secondary: #fc4714;
$color-Accent-1: #4455c7;
$color-Accent-2: #ffc69a;
$color-Affirmative: #1c9a6c;
$color-attention: #e2662c;
$color-Negative: #d84727;
$color-Black: #191919;
$color-dark-Blue: #01233f;

// Border color
$field-border: #25282c;
$blockBorder: 1px solid #2e353a;

// scroll background color
$bg-scroll: #353535;

// linear-gradient
// $bg-linear: linear-gradient(90deg,
//     rgba(68, 85, 199, 1) 0%,
//     rgba(0, 55, 100, 1) 68%);
