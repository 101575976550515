@import "../../Assets/scss/variable.scss";

.header {
  background: $color-Dark;
  height: $headerHeight;
  padding: 0 1.5rem;
  @include breakpoint(md) {
    padding: 0 3rem;
  }
  transition: 0.3s ease all;

  &__content {
    overflow: hidden;
    color: $color-Light;
    margin: 0 auto;
    max-width: 1440px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;

    &__logo {
      font-size: 2rem;
      font-weight: bold;
      text-decoration: none;
      color: $color-Light;

      @media screen and (max-width: 1020px) {
        width: 120px;
      }
      @media screen and (max-width: 600px) {
        width: 100px;
      }
    }

    &__nav {
      top: 0;
      right: 100%;
      bottom: 0;
      width: 100%;
      height: 100vh;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: rgba($color-Dark, 0.9);
      backdrop-filter: blur(2px);
      transform: translate(0);
      transition: 0.3s ease transform;

      @media (min-width: 871px) {
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: $spacing-lg;
        @media (min-width: 871px) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;
          margin-right: calc(0.5rem + #{$spacing-md});
        }
        li {
          &:not(:last-child) {
            margin-bottom: $spacing-lg;
            @media (min-width: 871px) {
              margin-bottom: 0;
              margin-right: $spacing-md;
            }
          }
          a {
            text-decoration: none;
            color: inherit;
            padding: 0.75rem 1.25rem;
            border-radius: $borderRadius;
            transition: 0.3s ease all;

            &:hover {
              background: rgba($color-Light, 0.1);
            }

            &.active {
              background: $color-Light;
              color: $color-Dark;
              font-weight: bold;
            }

            &:active {
              background: rgba($color-Light, 0.3);
            }
          }
        }
      }

      &.isMenu {
        transform: translate(100%);
      }
    }

    &__toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 2rem;
      transition: 0.3s ease all;
      position: relative;

      &:hover {
        color: $color-Light;
      }

      @media (min-width: 871px) {
        display: none;
      }
    }
  }
}
