@import "../../Assets/scss/variable.scss";
.blog-container {
  //   padding: 2rem;

  .banner {
    position: relative;
    width: 100%;
    height: 370px;
    overflow: hidden;
    margin-bottom: 2rem;

    @media screen and (max-width: 700px) {
      height: 270px;
    }

    .banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #e8e8e8;
      font-size: 2.5rem;
      font-weight: 700;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      @media screen and (max-width: 400px) {
        font-size: 2rem;
      }
    }
  }

  .blog-page {
    margin-top: 20px;
    margin-bottom: 40px;

    .blog-card {
      margin-bottom: 20px;

      .card-img-top {
        height: 200px;
        object-fit: cover;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .card-title {
          // text-shadow: 2px 2px $color-Dark-1;
          margin-top: 10px;
          font-size: 28px;
          line-height: 25px;
          font-weight: 600;
          color: $color-Dark;
          // background: linear-gradient(
          //   45deg,
          //   $color-Pastel-Green,
          //   $color-Accent-1
          // );
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          @media (max-width: 1480px) {
            font-size: 24px;
            line-height: 22.4px;
          }
          // @media (max-width: 1240px) {
          //   text-align: center;
          // }
          @media (max-width: 600px) {
            font-size: 20px;
            // line-height: 17px;
          }
          @media (max-width: 440px) {
            font-size: 18px;
            line-height: 20px;
          }
        }

        .card-text {
          margin-top: 18px;
          font-size: 22px;
          line-height: 25px;
          font-weight: 400;
          color: $color-Dark-Details-text;

          @media (max-width: 1480px) {
            font-size: 20px;
            line-height: 22.4px;
          }
          @media (max-width: 600px) {
            font-size: 18px;
            line-height: 17px;
          }
          @media (max-width: 440px) {
            font-size: 14px;
            line-height: 15px;
          }
        }

        .btn {
          align-self: flex-end;
          background-color: $color-Pastel-Green;
          //   border: 1px solid $color-Pastel-Green;
          text-transform: capitalize;
          padding: 6px 18px;
          width: 100%;
          max-width: 150px;
          border-radius: 18px;
          margin-bottom: 20px;
          margin-top: 40px;
          font-size: 1.2rem;
          font-weight: 600;
          color: $color-Light-1;
          outline: none;
          border: 2px solid #264143;
          box-shadow: 3px 4px 0px 1px $color-Dark;

          &:link,
          &:visited {
            // text-transform: uppercase;
            text-decoration: none;
            // color: rgb(27, 27, 27);
            padding: 6px 18px;
            border: 1px solid $color-Dark;
            border-radius: 1000px;
            display: inline-block;
            transition: all 0.2s;
            position: relative;
          }

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 20px rgba(27, 27, 27, 0.5);
          }

          &:active {
            transform: translateY(-3px);
          }
        }
      }
    }

    .pagination {
      margin-top: 20px;

      .page-item {
        .page-link {
          color: $color-Pastel-Green; // Non-active button color

          &:hover {
            background-color: #e9ecef; // Hover background color
            color: #0056b3; // Hover text color
          }
        }

        &.active {
          .page-link {
            background-color: $color-Pastel-Green; // Active background color
            border-color: $color-Pastel-Green; // Active border color
            color: white; // Active text color

            &:hover {
              background-color: $color-Pastel-Green; // Active hover background color
              border-color: $color-Pastel-Green; // Active hover border color
              color: white; // Active hover text color
            }
          }
        }
      }
    }
  }
}
