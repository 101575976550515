@import "../../Assets/scss/variable.scss";

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f0f0f0;
  color: #333;

  h1 {
    // text-shadow: 2px 2px $color-Dark-1;
    margin-top: 10px;
    font-size: 36px;
    line-height: 30px;
    font-weight: 600;
    // color: #212121;
    background: linear-gradient(45deg, $color-Pastel-Green, $color-Accent-1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // text-align: center;

    @media (max-width: 1280px) {
      font-size: 30px;
      //   line-height: 22.4px;
    }
    @media (max-width: 740px) {
      text-align: center;
    }
    @media (max-width: 600px) {
      font-size: 28px;
      //   line-height: 17px;
    }
    @media (max-width: 440px) {
      font-size: 26px;
      //   line-height: 15px;
    }
  }

  p {
    // text-shadow: 2px 2px $color-Dark-1;
    margin-top: 18px;
    font-size: 22px;
    line-height: 25px;
    font-weight: 400;
    color: $color-Dark-Details-text;

    @media (max-width: 1480px) {
      font-size: 20px;
      line-height: 22.4px;
    }

    @media (max-width: 740px) {
      text-align: center;
    }
    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 17px;
    }
    @media (max-width: 440px) {
      font-size: 14px;
      line-height: 15px;
    }
  }

  .btn {
    align-self: flex-end;
    background-color: $color-Pastel-Green;
    //   border: 1px solid $color-Pastel-Green;
    text-transform: capitalize;
    padding: 6px 18px;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    border-radius: 18px;
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 1.2rem;
    font-weight: 600;
    color: $color-Light-1;
    outline: none;
    border: 2px solid #264143;
    box-shadow: 3px 4px 0px 1px $color-Dark;

    &:link,
    &:visited {
      // text-transform: uppercase;
      text-decoration: none;
      // color: rgb(27, 27, 27);
      padding: 6px 18px;
      border: 1px solid $color-Dark;
      border-radius: 1000px;
      display: inline-block;
      transition: all 0.2s;
      position: relative;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(27, 27, 27, 0.5);
    }

    &:active {
      transform: translateY(-3px);
    }
  }
}
