@import "./variable.scss";

// .roboto-regular {
//   font-family: "Roboto", sans-serif;
//   font-weight: 400;
//   font-style: normal;
// }

// .roboto-medium {
//   font-family: "Roboto", sans-serif;
//   font-weight: 500;
//   font-style: normal;
// }

// .roboto-bold {
//   font-family: "Roboto", sans-serif;
//   font-weight: 700;
//   font-style: normal;
// }

#root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  outline: none;
  width: 100%;
  background-color: $color-Light;

  // overflow: hidden;
  span,
  h4,
  h5,
  h6,
  a,
  p,
  li {
    font-family: $regulerFont !important;
  }

  h1,
  h2,
  h3 {
    font-family: $regulerFont;
  }

  a {
    text-decoration: none;
  }

  iframe {
    position: inherit !important;
  }

  .app-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  // * {
  //   scrollbar-width: thin;
  //   scrollbar-color: #4455c7 #e7e9ec;
  // }

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    border-radius: 10px;
    height: 4px;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #e7e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4455c7;
    border-radius: 10px;
    border: 0px solid #ffffff;
  }

  .MuiPickersDay-daySelected {
    background-color: #3847a7;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-default {
    cursor: default;
  }

  .keyboard-icon {
    width: 25px;
    height: 25px;
  }

  .keyboard-icon-bg {
    width: 20px;
    height: 20px;
  }
}
