.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #128c7e;
      color: white;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.content {
  position: relative;
  flex: 1;
  //   padding: 2rem;
}

.contact-bg {
  background-image: url("./Assets/images/Banner/worldmap.svg");
  background-position: center;
}
