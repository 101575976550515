@import "../../../Assets/scss/variable.scss";
.blog-post-container {
  .banner {
    position: relative;
    width: 100%;
    height: 370px;
    overflow: hidden;
    margin-bottom: 2rem;

    @media screen and (max-width: 700px) {
      height: 270px;
    }

    .banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #e8e8e8;
      font-size: 2.5rem;
      font-weight: 700;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      @media screen and (max-width: 400px) {
        font-size: 2rem;
      }
    }
  }

  .blog-detail {
    padding: 2rem;
    // padding: 0px 60px;
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;

    .title {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 44px;
      //   line-height: 37px;
      font-weight: 600;
      // text-align: center;
      // color: $color-Dark;
      background: linear-gradient(45deg, $color-Pastel-Green, $color-Accent-1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media (max-width: 1280px) {
        font-size: 24px;
        line-height: 22.4px;
      }
      @media (max-width: 1240px) {
        // text-align: center;
      }
    }

    .blog-image {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      border-radius: 10px;
    }

    h1 {
      margin-bottom: 20px;
      font-size: 2.5rem;
      color: #212529;
    }

    img {
      border-radius: 18px;
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    h3 {
      // text-shadow: 2px 2px $color-Dark-1;
      margin-top: 10px;
      font-size: 28px;
      line-height: 25px;
      font-weight: 600;
      color: $color-Dark;
      // background: linear-gradient(
      //   45deg,
      //   $color-Pastel-Green,
      //   $color-Accent-1
      // );
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      @media (max-width: 1480px) {
        font-size: 24px;
        line-height: 22.4px;
      }
      // @media (max-width: 1240px) {
      //   text-align: center;
      // }
      @media (max-width: 600px) {
        font-size: 20px;
        line-height: 17px;
      }
      @media (max-width: 440px) {
        font-size: 18px;
        line-height: 15px;
      }

      //   span {
      //     background: linear-gradient(
      //       45deg,
      //       $color-Pastel-Green,
      //       $color-Accent-1
      //     );
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;
      //   }
    }
    p {
      // text-shadow: 2px 2px $color-Dark-1;
      margin-top: 18px;
      font-size: 22px;
      line-height: 25px;
      font-weight: 400;
      color: $color-Dark-Details-text;

      @media (max-width: 1480px) {
        font-size: 20px;
        line-height: 22.4px;
      }
      @media (max-width: 600px) {
        font-size: 18px;
        line-height: 17px;
      }
      @media (max-width: 440px) {
        font-size: 14px;
        line-height: 15px;
      }
      // span {
      //   text-decoration: underline;
      //   background: linear-gradient(
      //     45deg,
      //     $color-Pastel-Green,
      //     $color-Accent-1
      //   );
      //   -webkit-background-clip: text;
      //   -webkit-text-fill-color: transparent;
      // }
    }
  }
}
