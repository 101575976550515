@import "../../Assets/scss/variable.scss";
.home-container {
  //   padding: 2rem;
  .img-height {
    // height: 80vh;
    max-height: 520px;
    height: 100%;
    object-fit: cover;

    @media (max-width: 900px) {
      //   height: 60vh;
      max-height: 420px;
    }
    @media (max-width: 600px) {
      //   height: 50vh;
      max-height: 320px;
    }
    // @media (max-width: 440px) {
    //   height: 50vh;
    // }

    ::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .carousel-indicators [data-bs-target] {
    border-radius: 18px;
    width: 20px;
    height: 10px;
    background-color: $color-Pastel-Green;
    @media (max-width: 600px) {
      border-radius: 12px;
      width: 16px;
      height: 8px;
    }
    @media (max-width: 440px) {
      border-radius: 12px;
      width: 14px;
      height: 6px;
    }
  }

  .carousel-caption {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-height: fit-content;
    // background: black;
    // opacity: 0.4;
    // border-radius: 12px;
    // box-shadow: 10px 10px 5px 0px #0000000f;
    // -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
    // -moz-box-shadow: 10px 10px 5px 0px #0000000f;
    // padding: 1rem;
    // background-color: rgba($color: $color-Dark, $alpha: 0.2);
    // filter: drop-shadow(10px 10px 5px 0px #0000000f);
    h3 {
      text-shadow: 2px 2px $color-Dark-1;
      font-size: 44px;
      line-height: 36px;
      font-weight: 600;
      color: $color-Light;
      @media (max-width: 900px) {
        font-size: 32px;
        line-height: 22.4px;
      }
      @media (max-width: 600px) {
        font-size: 24px;
        line-height: 17px;
      }
      @media (max-width: 440px) {
        font-size: 18px;
        line-height: 15px;
      }
    }
    p {
      text-shadow: 2px 2px $color-Dark-1;
      margin-top: 18px;
      font-size: 28px;
      line-height: 25px;
      font-weight: 500;
      color: $color-Light;

      @media (max-width: 900px) {
        font-size: 24px;
        line-height: 22.4px;
      }
      @media (max-width: 600px) {
        font-size: 18px;
        line-height: 17px;
      }
      @media (max-width: 440px) {
        font-size: 14px;
        line-height: 15px;
      }
    }
  }

  .about-sec {
    max-width: 1440px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    column-gap: 30px;

    @media (max-width: 1240px) {
      flex-direction: column-reverse;
      row-gap: 30px;
    }
    @media (max-width: 600px) {
      row-gap: 20px;
      padding: 0.5rem;
    }
    .image-sec {
      height: 310px;
      width: 50%;

      @media (max-width: 1240px) {
        width: 100%;
        height: 300px;
      }
      @media (max-width: 600px) {
        height: 230px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px 60px;
        box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
        // filter: drop-shadow(10px 10px 5px 0px #0000000f);
        -webkit-box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
        -moz-box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
      }
    }
    .txt-sec {
      width: 50%;
      @media (max-width: 1240px) {
        width: 100%;
        margin-top: 20px;
      }
      h3 {
        // text-shadow: 2px 2px $color-Dark-1;
        font-size: 44px;
        line-height: 36px;
        font-weight: 600;
        color: $color-Dark;
        @media (max-width: 1280px) {
          font-size: 32px;
          line-height: 22.4px;
        }
        @media (max-width: 1240px) {
          text-align: center;
        }
        @media (max-width: 600px) {
          font-size: 24px;
          line-height: 17px;
        }
        @media (max-width: 440px) {
          font-size: 18px;
          line-height: 15px;
        }

        span {
          background: linear-gradient(
            45deg,
            $color-Pastel-Green,
            $color-Accent-1
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      p {
        // text-shadow: 2px 2px $color-Dark-1;
        margin-top: 18px;
        font-size: 22px;
        line-height: 25px;
        font-weight: 400;
        color: $color-Dark-Details-text;

        @media (max-width: 1280px) {
          font-size: 20px;
          line-height: 22.4px;
        }
        @media (max-width: 600px) {
          font-size: 18px;
          line-height: 17px;
        }
        @media (max-width: 440px) {
          font-size: 14px;
          line-height: 15px;
        }
        // span {
        //   text-decoration: underline;
        //   background: linear-gradient(
        //     45deg,
        //     $color-Pastel-Green,
        //     $color-Accent-1
        //   );
        //   -webkit-background-clip: text;
        //   -webkit-text-fill-color: transparent;
        // }
      }
    }
  }

  .services-sec {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    max-width: 1440px;
    margin: 0 auto;
    padding: 1rem;
    row-gap: 10px;
    column-gap: 5px;
    background: $color-Dark-1;
    border-radius: 18px;
    // margin: 0px 10px;

    @media (max-width: 1460px) {
      margin-left: 10px;
      margin-right: 10px;
    }
    @media (max-width: 1030px) {
      align-items: center;
      justify-content: space-around;
    }

    @media (max-width: 900px) {
      margin-top: 10px;
    }

    // @media (max-width: 600px) {
    //   margin: 0px 7px;
    // }

    .services-info-sec {
      width: 33%;

      @media (max-width: 1060px) {
        width: 45%;
        // margin: 0 auto;
      }
      @media (max-width: 680px) {
        width: 100%;
      }

      .services-info {
        display: flex;
        column-gap: 10px;

        .services-icon {
          padding: 0 !important;
        }

        .info-title {
          background: linear-gradient(45deg, $color-Light-1, $color-Accent-1);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          margin-top: 18px;
          font-size: 22px;
          line-height: 25px;
          font-weight: 600;

          @media (max-width: 900px) {
            font-size: 20px;
            line-height: 22.4px;
          }
          @media (max-width: 600px) {
            font-size: 18px;
            line-height: 17px;
          }
          @media (max-width: 440px) {
            font-size: 14px;
            line-height: 15px;
          }
        }
        .info-des {
          margin-top: 18px;
          font-size: 18px;
          line-height: 25px;
          font-weight: 400;
          color: $color-White-Details-text;

          @media (max-width: 1280px) {
            font-size: 16px;
            line-height: 22.4px;
          }
          @media (max-width: 600px) {
            font-size: 14px;
            line-height: 17px;
          }
          @media (max-width: 440px) {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }

  .product-btn {
    max-width: 165px;
    width: 100%;
    margin: 0 auto;
    .btn {
      //   align-self: flex-end;
      //   background-color: $color-Pastel-Green;
      background-color: $color-Light-1;
      //   border: 1px solid $color-Pastel-Green;
      text-transform: capitalize;
      padding: 10px 18px;
      width: 100%;
      max-width: 150px;
      border-radius: 18px;
      margin-bottom: 20px;
      //   margin-top: 40px;
      font-size: 1.2rem;
      font-weight: 600;
      color: $color-Dark;
      //   color: $color-Light-1;
      outline: none;
      border: 2px solid #264143;
      box-shadow: 3px 4px 0px 1px $color-Dark;

      &:link,
      &:visited {
        // text-transform: uppercase;
        text-decoration: none;
        // color: rgb(27, 27, 27);
        padding: 10px 18px;
        border: 1px solid $color-Dark;
        border-radius: 1000px;
        display: inline-block;
        transition: all 0.2s;
        position: relative;
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(27, 27, 27, 0.5);
      }

      &:active {
        transform: translateY(-3px);
      }
    }
  }
  .products-sec {
    max-width: 1440px;
    margin: 0 auto;
    padding: 1rem;
    .slider {
      height: 370px;
      margin: auto;
      position: relative;
      width: 90%;
      display: grid;
      place-items: center;
      overflow: hidden;

      ::before {
        top: 0;
        left: 0;
      }

      ::after {
        top: 0;
        right: 0;
        transform: rotateZ(180deg);
      }

      ::before,
      ::after {
        // background: linear-gradient(
        //   to right,
        //   rgba(255, 255, 255, 1) 0%,
        //   rgba(255, 255, 255, 0) 100%
        // );
        content: "";
        height: 100%;
        position: absolute;
        width: 15%;
        z-index: 2;
      }

      .slider-track {
        display: flex;
        width: calc(
          290px * 22
        ); // Adjust width to fit double the number of slides for smooth looping
        animation: scroll 40s linear infinite; // Adjust duration as needed

        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(
              calc(-290px * 11)
            ); // Slide by the total width of 11 images
          }
        }

        &:hover {
          animation-play-state: paused;
        }

        .slide {
          width: 290px;
          height: 300px;
          display: flex;
          align-items: center;
          padding: 15px;
          //   transition: transform 0.5s ease-in;
          flex-direction: column;

          h3 {
            margin-top: 10px;
            font-size: 22px;
            //   line-height: 37px;
            // font-weight: 600;
            text-align: center;
            color: $color-Dark;
            @media (max-width: 600px) {
              font-size: 20px;
            }
            @media (max-width: 440px) {
              font-size: 18px;
            }
          }

          img {
            width: 100%;
            object-fit: cover;
            border-radius: 16px;
            cursor: pointer;
            transition: transform 0.3s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }
  .blog-sec-header {
    margin-top: 10px;
    font-size: 44px;
    //   line-height: 37px;
    font-weight: 600;
    text-align: center;
    // color: $color-Dark;
    background: linear-gradient(45deg, $color-Pastel-Green, $color-Accent-1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 22.4px;
    }
    @media (max-width: 1240px) {
      text-align: center;
    }
    // @media (max-width: 600px) {
    //   font-size: 22px;
    // }
    // @media (max-width: 440px) {
    //   font-size: 18px;
    // }
  }
  .blog-sec {
    max-width: 1440px;
    margin: 0 auto;
    padding: 1rem;
    margin-bottom: 40px;
    // margin: 0px 10px;
    .recent-blog-sec {
      margin-top: 20px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   column-gap: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      //   .link-class {

      @media screen and (max-width: 1060px) {
        flex-direction: column;
        /* width: 50%; */
        justify-content: center;
      }
      .blogs {
        // width: 33%;
        // @media (max-width: 1230px) {
        //   width: 50%;
        // }

        flex: 1 1 calc(33% - 20px);
        box-sizing: border-box;
        margin: 10px;
        background: #fff;
        // border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.05);
        }

        @media screen and (max-width: 1060px) {
          width: 50%;
          margin: 0 auto;
          margin-top: 20px;
        }

        @media (max-width: 1024px) {
          .blogs {
            flex: 1 1 calc(50% - 20px);
          }
        }

        @media (max-width: 768px) {
          .blogs {
            flex: 1 1 100%;
          }
        }
        @media screen and (max-width: 730px) {
          width: 100%;
        }

        .blog-img-sec {
          cursor: pointer;
          width: 100%;
          height: 250px;

          // width: 350px;
          img {
            border-radius: 18px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      h3 {
        // text-shadow: 2px 2px $color-Dark-1;
        margin-top: 10px;
        font-size: 28px;
        line-height: 25px;
        font-weight: 600;
        color: $color-Dark;
        // background: linear-gradient(
        //   45deg,
        //   $color-Pastel-Green,
        //   $color-Accent-1
        // );
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        @media (max-width: 1480px) {
          font-size: 24px;
          line-height: 22.4px;
        }
        // @media (max-width: 1240px) {
        //   text-align: center;
        // }
        @media (max-width: 600px) {
          font-size: 20px;
          //   line-height: 17px;
        }
        @media (max-width: 440px) {
          font-size: 18px;
          line-height: 20px;
        }

        //   span {
        //     background: linear-gradient(
        //       45deg,
        //       $color-Pastel-Green,
        //       $color-Accent-1
        //     );
        //     -webkit-background-clip: text;
        //     -webkit-text-fill-color: transparent;
        //   }
      }
      p {
        // text-shadow: 2px 2px $color-Dark-1;
        margin-top: 18px;
        font-size: 22px;
        line-height: 25px;
        font-weight: 400;
        color: $color-Dark-Details-text;

        @media (max-width: 1480px) {
          font-size: 20px;
          line-height: 22.4px;
        }
        @media (max-width: 600px) {
          font-size: 18px;
          line-height: 17px;
        }
        @media (max-width: 440px) {
          font-size: 14px;
          line-height: 15px;
        }
        // span {
        //   text-decoration: underline;
        //   background: linear-gradient(
        //     45deg,
        //     $color-Pastel-Green,
        //     $color-Accent-1
        //   );
        //   -webkit-background-clip: text;
        //   -webkit-text-fill-color: transparent;
        // }
      }
      //   }
    }
  }

  .contact-us-sec {
    max-width: 840px;
    margin: 0 auto;
    padding: 1rem;
    // background-color: $color-Pastel-Green;
    margin-bottom: 20px;
    margin-top: 40px;
    border-radius: 18px;
    background: linear-gradient(45deg, $color-Pastel-Green, $color-Accent-1);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;

    @media screen and (max-width: 860px) {
      max-width: 600px;
    }
    @media screen and (max-width: 610px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    h3 {
      // text-shadow: 2px 2px $color-Dark-1;
      margin-top: 10px;
      font-size: 28px;
      line-height: 25px;
      font-weight: 600;
      color: $color-Light;
      text-align: center;

      @media (max-width: 1280px) {
        font-size: 24px;
        line-height: 22.4px;
      }
      // @media (max-width: 1240px) {
      //   text-align: center;
      // }
      @media (max-width: 600px) {
        font-size: 20px;
        line-height: 17px;
      }
      @media (max-width: 440px) {
        font-size: 18px;
        line-height: 15px;
      }

      //   span {
      //     background: linear-gradient(
      //       45deg,
      //       $color-Pastel-Green,
      //       $color-Accent-1
      //     );
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;
      //   }
    }

    .cta {
      margin-top: 20px !important;
      border: 2px solid black;
      border-radius: 50px;
      position: relative;
      margin: auto;
      padding: 9px 18px;
      transition: all 0.2s ease;
      //   border: none;
      background: none;
      cursor: pointer;
      display: flex; // Center text and svg inside the button
      align-items: center; // Center items vertically
      justify-content: center;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border-radius: 50px;
        background: $color-Dark;
        width: 70px;
        height: 45px;
        transition: all 0.3s ease;

        @media screen and (max-width: 920px) {
          width: 100%;
        }
      }

      span {
        position: relative;
        font-family: "Ubuntu", sans-serif;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.05em;
        color: $color-Light;
      }

      svg {
        position: relative;
        top: 0;
        margin-left: 10px;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: $color-Light;
        stroke-width: 2;
        transform: translateX(-5px);
        transition: all 0.3s ease;
      }

      &:hover {
        &:before {
          width: 100%;
          background: $color-Dark;
        }

        svg {
          transform: translateX(0);
        }
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }
}
