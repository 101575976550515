@import "../../Assets/scss/variable.scss";

// $color-Dark: #212121; // Background or text if white background
// $color-Dark-1: #191919;

// $color-Light: #e8e8e8; //Background or text if Black background
// $color-Light-1: #f5f5f5;

// $color-Pastel-Green: #5c7457; //for accent

// $color-White-Details-text: #d4d6d8; //if black background

// $color-Dark-Details-text: #5d5d5d; //if white background

// $color-Accent-1: #4455c7;

.about-container {
  //   padding: 2rem;
  //   background-color: #f8f9fa;

  .banner {
    position: relative;
    width: 100%;
    height: 370px;
    overflow: hidden;
    margin-bottom: 2rem;

    @media screen and (max-width: 700px) {
      height: 270px;
    }

    .banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #e8e8e8;
      font-size: 2.5rem;
      font-weight: 700;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      @media screen and (max-width: 400px) {
        font-size: 2rem;
      }
    }
  }

  h1 {
    color: #234567;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    color: #565656;
    font-size: 1.1rem;
  }

  .about-card {
    display: flex;
    align-items: center;
    column-gap: 20px;
    max-width: 1440px;
    margin: 0 auto;

    @media screen and (max-width: 1450px) {
      margin-left: 10px;
      margin-right: 10px;
    }
    @media screen and (max-width: 1240px) {
      flex-direction: column;
      row-gap: 20px;
    }
    .card {
      width: 33%;
      height: 300px;
      --bg: #212121;
      //   --bg: linear-gradient(45deg, #5c7457, #4455c7);
      --hover-bg: #5c7457; // $color-Pastel-Green
      --hover-text: #f5f5f5;
      text-align: center;
      background: var(--bg);
      padding: 1.5em;
      padding-block: 1.8em;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      transition: 0.3s cubic-bezier(0.6, 0.4, 0, 1), transform 0.15s ease;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 18px;
      gap: 1em;
      box-shadow: 10px 10px 5px 0px #0000000f;
      -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
      -moz-box-shadow: 10px 10px 5px 0px #0000000f;

      @media screen and (max-width: 1240px) {
        width: 100%;
      }

      &__body {
        color: #212121; // $color-Dark
        line-height: 1.5em;
        font-size: 1em;
      }

      > :not(span) {
        transition: 0.3s cubic-bezier(0.6, 0.4, 0, 1);
      }

      > strong {
        display: block;
        font-size: 44px;
        letter-spacing: -0.035em;
        color: #e8e8e8;
      }

      span {
        padding: 1rem;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--hover-text);
        border-radius: 5px;
        font-weight: bold;
        top: 100%;
        transition: all 0.3s cubic-bezier(0.6, 0.4, 0, 1);
      }

      &:hover span {
        top: 0;
        font-size: 1.5em;
      }

      &:hover {
        background: var(--hover-bg);
      }

      &:hover > div,
      &:hover > strong {
        opacity: 0;
      }
    }
  }

  .row-reverse {
    flex-direction: row-reverse;

    @media screen and (max-width: 740px) {
      flex-direction: column-reverse;
    }
  }

  .responsive-about-sec {
    max-width: 1440px;
    margin: 0 auto;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    // align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: center;
    margin-top: 30px;
    @media screen and (max-width: 740px) {
      flex-direction: column-reverse;
    }

    .img-div {
      @media screen and (max-width: 740px) {
        display: flex;
        justify-content: center;
      }
    }
    .about-img {
      //   margin-top: 20px;
      width: 400px;
      height: 250px;
      object-fit: cover;
      border-radius: 18px;

      @media screen and (max-width: 440px) {
        width: 300px;
        height: 220px;
      }
    }

    .txt-div {
      width: 45%;

      @media screen and (max-width: 740px) {
        width: 100%;
      }
      h3 {
        // text-shadow: 2px 2px $color-Dark-1;
        margin-top: 10px;
        font-size: 36px;
        line-height: 30px;
        font-weight: 600;
        // color: #212121;
        background: linear-gradient(
          45deg,
          $color-Pastel-Green,
          $color-Accent-1
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // text-align: center;

        @media (max-width: 1280px) {
          font-size: 30px;
          line-height: 22.4px;
        }
        @media (max-width: 740px) {
          text-align: center;
        }
        @media (max-width: 600px) {
          font-size: 28px;
          //   line-height: 17px;
        }
        @media (max-width: 440px) {
          font-size: 26px;
          //   line-height: 15px;
        }
      }

      p {
        // text-shadow: 2px 2px $color-Dark-1;
        margin-top: 18px;
        font-size: 22px;
        line-height: 25px;
        font-weight: 400;
        color: $color-Dark-Details-text;

        @media (max-width: 1480px) {
          font-size: 20px;
          line-height: 22.4px;
        }

        @media (max-width: 740px) {
          text-align: center;
        }
        @media (max-width: 600px) {
          font-size: 18px;
          line-height: 17px;
        }
        @media (max-width: 440px) {
          font-size: 14px;
          line-height: 15px;
        }
      }
    }
  }

  .cta {
    border: 2px solid linear-gradient(45deg, #5c7457, #4455c7);
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    padding: 1rem;
    border-radius: 50px;
    background-color: $color-Light-1;
    position: relative;
    margin: 0 auto;
    padding: 9px 18px;
    transition: all 0.2s ease;
    // border: none;
    background: none;
    cursor: pointer;
    display: flex; // Center text and svg inside the button
    align-items: center; // Center items vertically
    justify-content: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      border-radius: 50px;
      background: $color-Dark;
      width: 70px;
      height: 45px;
      transition: all 0.3s ease;

      @media screen and (max-width: 920px) {
        width: 100%;
      }
    }

    span {
      position: relative;
      font-family: "Ubuntu", sans-serif;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.05em;
      color: $color-Affirmative;
    }

    svg {
      position: relative;
      top: 0;
      margin-left: 10px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: $color-Affirmative;
      stroke-width: 2;
      transform: translateX(-5px);
      transition: all 0.3s ease;
    }

    &:hover {
      &:before {
        width: 100%;
        background: $color-Dark;
      }

      svg {
        transform: translateX(0);
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }
}
