@import "../../Assets/scss/variable.scss";

.contactus-container {
  //   padding: 2rem;

  .banner {
    position: relative;
    width: 100%;
    height: 370px;
    overflow: hidden;
    margin-bottom: 2rem;

    @media screen and (max-width: 700px) {
      height: 270px;
    }

    .banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #e8e8e8;
      font-size: 2.5rem;
      font-weight: 700;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      @media screen and (max-width: 400px) {
        font-size: 2rem;
      }
    }
  }

  .contact-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: $color-Light-1;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 40px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (max-width: 620px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    .form-group {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 8px;
        font-weight: bold;
        color: $color-Dark;
      }

      .form-control {
        width: 100%;
        padding: 10px;
        // border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;

        outline: none;
        border: 2px solid #264143;
        box-shadow: 3px 4px 0px 1px $color-Pastel-Green;
        // width: 290px;
        padding: 12px 10px;
        border-radius: 4px;
        font-size: 15px;

        &:focus {
          transform: translateY(4px);
          box-shadow: 1px 2px 0px 0px $color-Pastel-Green;
        }
      }

      .error-message {
        color: $color-Negative;
        font-size: 14px;
        margin-top: 5px;
      }
    }

    .react-select-container {
      outline: none;
      border: 2px solid #264143;
      box-shadow: 3px 4px 0px 1px $color-Pastel-Green;
      border-radius: 4px;

      &:focus {
        outline: none !important; /* Remove blue border */
        box-shadow: none !important; /* Remove default focus shadow */
        border-color: transparent !important;
        border: none !important;
        // border-color: #007bff; /* Optional: Change border color on focus */
      }
      .react-select__control {
        box-shadow: none !important; /* Remove default focus shadow */
        // border-color: #264143 !important; /* Custom border color */
        // border: 1px solid #ccc;
        // border-radius: 4px;
        border: none;
        padding: 2px;
      }

      .react-select__menu {
        border-radius: 4px;
        border: 1px solid #264143;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        /* Custom scrollbar styles */
        ::-webkit-scrollbar {
          width: 12px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #aacfa2;
          border-radius: 20px;
          border: 3px solid #f1f1f1;
        }

        ::-webkit-scrollbar-thumb:hover {
          background-color: #8bbf88;
        }

        .react-select__option {
          background-color: white;
          color: #264143;
          cursor: pointer;

          &:hover {
            background-color: #aacfa2;
            color: $color-Dark;
          }

          &.react-select__option--is-focused {
            background-color: #aacfa2;
            color: $color-Dark;
          }

          &.react-select__option--is-selected {
            background-color: $color-Pastel-Green;
            color: white;
          }
        }
      }

      @media (max-width: 600px) {
        .react-select__control {
          height: 36px;
          padding: 2px;
        }

        .react-select__value-container {
          height: 36px;
          padding: 0 8px;
        }

        .react-select__single-value {
          font-size: 14px;
        }

        .react-select__placeholder {
          font-size: 14px;
        }
      }
    }
    .submit-btn {
      //   border: 1px solid $color-Dark;
      padding: 10px 24px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 200px;
      border-radius: 18px;
      margin-bottom: 20px;
      margin-top: 40px;
      font-size: 1.2rem;
      font-weight: 600;
      color: $color-Dark;
      outline: none;
      border: 2px solid #264143;
      box-shadow: 3px 4px 0px 1px $color-Pastel-Green;
      background-color: #f5f5f5 !important;

      &:link,
      &:visited {
        text-transform: uppercase;
        text-decoration: none;
        color: rgb(27, 27, 27);
        padding: 10px 30px;
        border: 1px solid $color-Dark;
        border-radius: 1000px;
        display: inline-block;
        transition: all 0.2s;
        position: relative;
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(27, 27, 27, 0.5);
      }

      &:active {
        transform: translateY(-3px);
      }

      &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 100px;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
        transition: all 0.3s;
      }

      //   &:hover::after {
      //     background-color: $color-Pastel-Green;
      //     transform: scaleX(1.4) scaleY(1.5);
      //     opacity: 0;
      //   }
    }

    @media (max-width: 600px) {
      padding: 15px;

      .form-group {
        label {
          font-size: 14px;
        }

        .form-control {
          padding: 8px;
          font-size: 14px;
        }

        .error-message {
          font-size: 12px;
        }
      }

      .submit-btn {
        padding: 8px 10px;
        font-size: 16px;
      }
    }

    @media (max-width: 400px) {
      padding: 10px;

      .form-group {
        label {
          font-size: 12px;
        }

        .form-control {
          padding: 6px;
          font-size: 12px;
        }

        .error-message {
          font-size: 10px;
        }
      }

      .submit-btn {
        padding: 6px 8px;
        font-size: 14px;
      }
    }
  }
}
